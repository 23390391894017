import React from "react"
import cross from "../assets/images/screenshots/cross.png"
import compare from "../assets/images/screenshots/eventim-compare.png"
import feature1 from "../assets/images/screenshots/feature1.jpg"
import feature2 from "../assets/images/screenshots/feature2.jpg"
import feature3 from "../assets/images/screenshots/feature3.jpg"
import Layout from "../components/Layout"

const Compare = () => (
  <Layout>
    <div>
      <section class="pt-4">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-md-5 col-lg-6 order-md-2">
              <img
                src={compare}
                class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
                alt="compare lumis"
              />
            </div>
            <div
              class="col-12 col-md-7 col-lg-6 order-md-1 aos-init aos-animate"
              data-aos="fade-up"
            >
              <h1 class="display-3 text-center text-md-left">
                Why <span class="text-primary">switch?</span>
              </h1>

              <p class="lead text-center text-md-left text-muted mb-6 mb-lg-8">
                A comparison between Lumis.ai and EVENTIM.light
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-8 pt-md-11 pb-5 bg-gray-200">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1>
                Stop paying <span className="text-danger">hidden</span> fees.
              </h1>

              <p className="font-size-lg text-gray-700 mb-7 mb-md-9">
                Here's what EVENTIM.light actually charges
              </p>
            </div>
          </div>
          <table class="table table-borderless  table-responsive rounded">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Lumis.ai</th>
                <th scope="col">EVENTIM.Light</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Basic Fee</th>
                <td>3,5%</td>
                <td>
                  3,5% + 0,99€ for tickets above 7,00€ &nbsp;
                  <a
                    href="https://www.eventim-light.com/de/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="small"
                  >
                    {" "}
                    Source
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <th scope="row">Online booking fee</th>
                <td>No extra fee</td>
                <td>
                  0 € to 2 € per ticket on top &nbsp;
                  <a
                    href="https://www.eventim-light.com/de/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="small"
                  >
                    {" "}
                    Source
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <th scope="row">Event cancellation fee</th>
                <td>No extra fee</td>
                <td>
                  1,50€ per ticket &nbsp;
                  <a
                    href="https://www.eventim-light.com/de/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="small"
                  >
                    {" "}
                    Source
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <th scope="row">VVK fees</th>
                <td>Not applicable</td>
                <td>
                  10% VVK fee instead of basic fee &nbsp;
                  <a
                    href="https://www.eventim-light.com/de/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="small"
                  >
                    {" "}
                    Source
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <th scope="row">Ticket shipping fee</th>
                <td>Not applicable</td>
                <td>
                  Up to 5,90 € &nbsp;
                  <a
                    href="https://www.eventim-light.com/de/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="small"
                  >
                    {" "}
                    Source
                  </a>{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section className="pt-8 pt-md-11 pb-5 bg-gray-200">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1>
                Stop being<span className="text-danger"> exploited.</span>
              </h1>

              <p className="font-size-lg text-gray-700 mb-7 mb-md-9">
                Take a look at EVENTIM.light's fine print
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <div class="card card-border border-danger shadow-lg mb-6 mb-md-8 lift lift-lg">
                <div class="card-body text-center">
                  <h4 class="font-weight-bold">Delayed Funding</h4>

                  <p class="text-gray-700 mb-5">
                    EVENTIM.light generally waits up to 5 days <strong>after</strong> your event to
                    transfer your funds.
                  </p>

                  <a
                    href="https://www.eventim-light.com/de/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span class="badge badge-pill badge-dark-soft">
                      <span class="h6 text-uppercase">§9 | terms & conditions</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="card card-border border-danger shadow-lg mb-6 mb-md-8 lift lift-lg">
                <div class="card-body text-center">
                  <h4 class="font-weight-bold">Branding Obligations</h4>

                  <p class="text-gray-700 mb-5">
                    Terms expects you to make "reasonable efforts" to promote their brand.
                  </p>

                  <a
                    href="https://www.eventim-light.com/de/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span class="badge badge-pill badge-dark-soft">
                      <span class="h6 text-uppercase">§10 | terms & conditions</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="card card-border border-danger shadow-lg mb-6 mb-md-8 lift lift-lg">
                <div class="card-body text-center">
                  <h4 class="font-weight-bold">Solicitation of Attendees</h4>

                  <p class="text-gray-700 mb-5">
                    Terms include giving permission to send offers to your customers for other
                    events.
                  </p>

                  <a
                    href="https://www.eventim-light.com/de/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span class="badge badge-pill badge-dark-soft">
                      <span class="h6 text-uppercase">§11 | terms & conditions</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-8 pt-md-11 pb-5 bg-gray-200">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1>
                Start using <span className="text-success">advanced</span> ticketing.
              </h1>

              <p className="font-size-lg text-gray-700 mb-7 mb-md-9">
                A selection of features you won't have with EVENTIM.light
              </p>
            </div>
          </div>
          <table class="table border-light table-responsive  rounded">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Lumis.ai</th>
                <th scope="col">EVENTIM.Light</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Instant Payout</th>
                <td>Money instantly available</td>
                <td>
                  Money payout only <strong>after</strong> the event&nbsp;{" "}
                  <a
                    href="https://www.eventim-light.de/haeufige-fragen/#c155"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="small"
                  >
                    {" "}
                    Source
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <th scope="row">Email Capture</th>
                <td>GDPR compliant email capture</td>
                <td>
                  Restricted access to emails{" "}
                  <a
                    href="https://www.eventim-light.de/haeufige-fragen/#c155"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="small"
                  >
                    {" "}
                    Source
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <th scope="row">Payment Methods</th>
                <td>
                  Credit Card, SOFORT, Pay via Invoice, <br /> Apple Pay, Google Pay
                </td>
                <td>
                  Credit Card, SOFORT, PayPal, Giropay{" "}
                  <a
                    href="https://www.eventim-light.de/haeufige-fragen/#c155"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="small"
                  >
                    {" "}
                    Source
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <th scope="row">Integrations</th>
                <td>
                  Google Analytics, Facebook Pixel, <br />
                  Snapchat Pixel, Slack Notifications, etc
                </td>
                <td>Not available </td>
              </tr>
              <tr>
                <th scope="row">Custom Domain</th>
                <td>Link your own domain</td>
                <td>Not available </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section className="pt-8 pt-md-11 bg-gradient-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1>
                <strong>Lumis.ai</strong>
                <br />
                Not just different. <span className="text-success">10X</span> better.
              </h1>

              <p className="font-size-lg text-gray-700 mb-7 mb-md-9">
                Nobody in the industry is able to offer these features.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up">
              <div className="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
                <img src={feature1} alt="..." className="card-img-top" />

                <div className="position-relative">
                  <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </div>

                <div className="card-body position-relative">
                  <div class="position-relative text-right mt-n8 mr-n4 mb-3">
                    <span class="badge badge-pill badge-warning">
                      <span class="h6 text-uppercase">Predictive Pricing</span>
                    </span>
                  </div>
                  <h3>More profits</h3>

                  <p className="text-muted">
                    Our AI is learning with every ticket sale. We can help you set prices based on
                    data.
                  </p>

                  <a
                    href="#!"
                    className="font-weight-bold text-decoration-none text-warning d-none"
                  >
                    Watch example
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-md-4 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
                <img src={feature2} alt="..." className="card-img-top" />

                <div className="position-relative">
                  <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </div>

                <div className="card-body position-relative">
                  <div class="position-relative text-right mt-n8 mr-n4 mb-3">
                    <span class="badge badge-pill badge-success">
                      <span class="h6 text-uppercase">Auto Surveys</span>
                    </span>
                  </div>
                  <h3>More insights</h3>

                  <p className="text-muted">
                    We send surveys after ticket purchase and after the event and automatically
                    evaluate.
                  </p>

                  <a
                    href="#!"
                    className="font-weight-bold text-decoration-none text-success d-none"
                  >
                    Watch example
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-md-4 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="card shadow-light-lg lift lift-lg">
                <img src={feature3} alt="..." className="card-img-top" />

                <div className="position-relative">
                  <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </div>

                <div className="card-body position-relative">
                  <div class="position-relative text-right mt-n8 mr-n4 mb-3">
                    <span class="badge badge-pill badge-primary">
                      <span class="h6 text-uppercase">Email Capture</span>
                    </span>
                  </div>
                  <h3>More Contacts</h3>

                  <p className="text-muted">
                    Collect more emails that are GDPR compliant and will grow your audience by a
                    factor of 3X.
                  </p>

                  <a
                    href="#!"
                    className="font-weight-bold text-decoration-none text-primary d-none"
                  >
                    Watch example
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-8 pt-md-10">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">
              <h2 class="font-weight-bold">
                What you<span class="text-success"> won't </span>get with Lumis.ai
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="row align-items-center mb-8 aos-init aos-animate" data-aos="fade-up">
                <div class="col-3 col-lg-5">
                  <img src={cross} alt="..." class="img-fluid" />
                </div>
                <div class="col-9 col-lg-7">
                  <h3 class="font-weight-bold mb-2">No links to other events</h3>

                  <p class="text-gray-700 mb-0">
                    Never tolerate other events being promoted from your ticketing page.
                  </p>
                </div>
              </div>

              <div class="row align-items-center mb-8 aos-init aos-animate" data-aos="fade-up">
                <div class="col-3 col-lg-5">
                  <img src={cross} alt="..." class="img-fluid" />
                </div>
                <div class="col-9 col-lg-7">
                  <h3 class="font-weight-bold mb-2">No branding wars</h3>

                  <p class="text-gray-700 mb-0">
                    We’re not in competition with your brand. Your logo is always primary.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="row align-items-center mb-8 aos-init aos-animate" data-aos="fade-up">
                <div class="col-3 col-lg-5">
                  <img src={cross} alt="..." class="img-fluid" />
                </div>
                <div class="col-9 col-lg-7">
                  <h3 class="font-weight-bold mb-2">No cross-promotion</h3>

                  <p class="text-gray-700 mb-0">
                    The data belongs to you. We'll never use it to promote other events.
                  </p>
                </div>
              </div>

              <div class="row align-items-center mb-8 aos-init aos-animate" data-aos="fade-up">
                <div class="col-3 col-lg-5">
                  <img src={cross} alt="..." class="img-fluid" />
                </div>
                <div class="col-9 col-lg-7">
                  <h3 class="font-weight-bold mb-2">No forced accounts </h3>

                  <p class="text-gray-700 mb-0">
                    Do you need another password in your life? Neither do your customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default Compare
